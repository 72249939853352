.btn {
  position: fixed;
  left: 20px;
  bottom: 133px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  visibility: visible;
  transition: visibility 0.2s ease;
  z-index: 30;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.icon {
  transform: rotate(90deg);
}
